import { onMounted, reactive } from 'vue'
import { useForm } from '../../Base/useForm'
import { FormHandler } from '@/utils/use/formHandler'
import { helpers, requiredIf } from '@vuelidate/validators'
import { settingBidder } from '@/http/user/userSettingApi'
import { useStore } from 'vuex'

export function useBidderSettingMessage () {
  const store = useStore()

  onMounted(() => {
    const formDataArray = Object.keys(formData)
    const settings = store.getters['auth/getUser'].user_setting
    formDataArray.map((field) => {
      formData[field] = settings?.[field]
    })
  })

  const formData = reactive({
    message_to_email: false,
    start_with_time: false,
    time_before_start: '',
    preliminary_publication: false,
    changes_published: false,
    cancel_published: false,
    extension: false,
    ending: false,
    publication_protocol: false
  })

  onMounted(() => {
    formData.time_before_start = '00:10'
  })

  const rules = {
    time_before_start: {
      requiredIf: helpers.withMessage('Обязательное поле', requiredIf(() => formData.start_with_time))
    }
  }

  const formAction = async () => {
    await settingBidder(formData)
    await store.dispatch('setAlert', {
      type: 'success',
      description: 'Настройки успешно сохранены'
    })
  }

  const form = FormHandler(formAction)

  return {
    ...useForm(rules, formData, form.handlerErrors),
    serverErrors: form.serverErrors
  }
}
