<template>
  <div class="block__subtitle mb-40">{{ title }}</div>
  <form class="form" @submit.prevent="onSubmit">
    <div class="form__inner">
      <div class="form__subtitle mb-30">Внутрисистемные сообщения</div>

      <VCheckbox
        id="message_to_email"
        name="message_to_email"
        class-form="form-checkbox mb-0"
        is-switch
        v-model="formData.message_to_email"
      >
        <template #default="slotProps">
          <label :for="slotProps.for" :class="slotProps.class">
            Уведомления меня посредством e-mail
          </label>
        </template>
      </VCheckbox>

      <VCheckbox
        id="start_with_time"
        name="start_with_time"
        class-form="form-checkbox mb-20"
        is-switch
        v-model="formData.start_with_time"
      >
        <template #default="slotProps">
          <label :for="slotProps.for" :class="slotProps.class">
            Старт ТП с таймером
          </label>
        </template>
      </VCheckbox>

      <VMask
        regex="00:00"
        mask-custom="00:00"
        :mask-type="true"
        id="time_before_start"
        name="time_before_start"
        is-range
        :is-masked-value="true"
        :range="range"

        v-model="formData.time_before_start"
        :errors="v$.time_before_start?.$errors"
        :server-errors="serverErrors.value?.time_before_start"

        @blur="validateField"
        @input="validateField"

        :class-input="['input input-long input-placeholder-black']"
        placeholder="Время уведомления"
      />

      <VCheckbox
        id="preliminary_publication"
        name="preliminary_publication"
        class-form="form-checkbox mb-0"
        is-switch
        v-model="formData.preliminary_publication"
      >
        <template #default="slotProps">
          <label :for="slotProps.for" :class="slotProps.class">
            Публикация ТП
          </label>
        </template>
      </VCheckbox>

      <VCheckbox
        id="changes_published"
        name="changes_published"
        class-form="form-checkbox mb-0"
        is-switch
        v-model="formData.changes_published"
      >
        <template #default="slotProps">
          <label :for="slotProps.for" :class="slotProps.class">
            Изменение опубликованной ТП
          </label>
        </template>
      </VCheckbox>

      <VCheckbox
        id="cancelTrade"
        name="cancelTrade"
        class-form="form-checkbox mb-0"
        is-switch
        v-model="formData.cancel_published"
      >
        <template #default="slotProps">
          <label :for="slotProps.for" :class="slotProps.class">
            Отмена опубликованной ТП
          </label>
        </template>
      </VCheckbox>

      <VCheckbox
        id="extension"
        name="extension"
        class-form="form-checkbox mb-0"
        is-switch
        v-model="formData.extension"
      >
        <template #default="slotProps">
          <label :for="slotProps.for" :class="slotProps.class">
            Продление ТП
          </label>
        </template>
      </VCheckbox>

      <VCheckbox
        id="ending"
        name="ending"
        class-form="form-checkbox mb-0"
        :value="formData.ending"
        is-switch
        v-model="formData.ending"
      >
        <template #default="slotProps">
          <label :for="slotProps.for" :class="slotProps.class">
            Окончание ТП
          </label>
        </template>
      </VCheckbox>

      <VCheckbox
        id="publication_protocol"
        name="publication_protocol"
        class-form="form-checkbox mb-20"
        is-switch
        v-model="formData.publication_protocol"
      >
        <template #default="slotProps">
          <label :for="slotProps.for" :class="slotProps.class">
            Публикация протокола о подведении итогов ТП
          </label>
        </template>
      </VCheckbox>

      <div class="form__buttons form__buttons-setting">
        <button type="submit" class="btn btn-primary btn-bg-green">
          Сохранить
        </button>
        <button type="button" class="btn btn-primary btn-bg-transparent" @click.prevent="goBack">
          Отмена
        </button>
      </div>
    </div>
  </form>
</template>

<script>
import VCheckbox from '@/components/ui/form/Checkbox/VCheckbox'
import VMask from '@/components/ui/form/VMask'
import { goBack } from '@/utils/goRoute'
import { useBidderSettingMessage } from '@/use/Form/Bidder/useBidderSettingMessage'
import { onBeforeMount, reactive } from 'vue'
import { getTimeStart } from '@/http/manualApi'

export default {
  props: {
    title: {
      type: String,
      required: true
    }
  },
  setup () {
    const times = reactive([])

    onBeforeMount(async () => {
      times.push(...await getTimeStart())
    })

    const range = {
      hours: {
        min: 0,
        max: 23,
        pos: [0, 1]
      },
      min: {
        min: 0,
        max: 59,
        pos: [3, 4]
      }
    }

    return {
      ...useBidderSettingMessage(),
      goBack,
      times,
      range
    }
  },
  components: {
    VMask,
    VCheckbox
  }
}
</script>
