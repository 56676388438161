<template>
  <SettingView
    :title="title"
  />
</template>
<script>
import SettingView from '@/components/views/VSettingView'
export default {
  props: {
    title: {
      type: String,
      required: true
    }
  },
  components: {
    SettingView
  }
}
</script>
